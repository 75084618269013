import ApiService from "./api.service";

const PredefinedService = {
  async getPredefinedRecords(field, options) {
    return await ApiService.commonGETRequest(
      `/predefinedrecords/${field}/`,
      JSON.parse(JSON.stringify(options))
    );
  },
  async addPredefinedRecord(data, options) {
    return await ApiService.commonPOSTRequest(
      `/predefinedrecords/`,
      JSON.parse(JSON.stringify(options)),
      data
    );
  },
  async updatePredefinedRecord(data, options) {
    return await ApiService.commonPUTRequest(
      `/predefinedrecords/edit/${data.id}/`,
      JSON.parse(JSON.stringify(options)),
      data
    );
  },
  async removePredefinedRecord(data, options) {
    return await ApiService.commonDELETERequest(
      `/predefinedrecords/edit/${data.id}/`,
      JSON.parse(JSON.stringify(options))
    );
  },
  async getPredefinedPairs(field) {
    return await ApiService.commonGETRequest(`/predefinedpairs/${field}/`);
  },
  async savePredefinedPairs(field, data) {
    return await ApiService.commonPOSTRequest(
      `/predefinedpairs/${field}/`,
      {},
      data
    );
  },
  async applyPredefined(patient, data) {
    return await ApiService.commonPOSTRequest(
      `patient/${patient}/session/${data.session.id}/predefined/`,
      {},
      data
    );
  },
  async getTemplates(field) {
    return await ApiService.commonGETRequest(
      `/predefinedrecords/${field}/templates/`
    );
  },
};

export default PredefinedService;
