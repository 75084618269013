<script>
import { mapActions, mapGetters } from "vuex";

const NOT_MODIFIED = 0;
const MODIFIED = 1;
const BEFORE_SYNCING = 2;
const SYNCING = 3;

export default {
  timers: {
    trySynchronize: { time: 5000, autostart: false, repeat: false },
  },
  data() {
    return {
      syncId: "id",
      time: 5000,
    };
  },
  watch: {
    statusChange: {
      handler() {
        if (this.getStatus(this.syncId) == MODIFIED) {
          this.setBeforeSyncing(this.syncId);
          this.$timer.stop("trySynchronize");
          this.$timer.start("trySynchronize");
        }
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters("autosave", ["getStatus", "statusChange"]),
    isSyncing() {
      return this.statusChange[this.syncId] == SYNCING;
    },
  },
  methods: {
    ...mapActions({
      setSyncing: "autosave/syncing",
      setSynced: "autosave/synced",
      setBeforeSyncing: "autosave/beforeSyncing",
      setNotModified: "autosave/notModified",
    }),
    beforeSyncing() {
      this.setBeforeSyncing(this.syncId);
    },
    syncing() {
      this.setSyncing(this.syncId);
    },
    synced() {
      this.setSynced(this.syncId);
    },
    notModified() {
      this.setNotModified(this.syncId);
    },
    validate() {
      return (
        this.getStatus(this.syncId) != BEFORE_SYNCING &&
        this.getStatus(this.syncId) != MODIFIED
      );
    },
    trySynchronize() {
      if (this.getStatus(this.syncId) != NOT_MODIFIED) {
        this.synchronize(true);
      } else {
        console.log("Synchronization stopped!");
      }
    },
    synchronize(autosave) {
      console.log("Autosave: ", autosave);
    },
    stopSyncingTimer() {
      this.$timer.stop("trySynchronize");
    },
    forceResetSyncing() {
      this.stopSyncingTimer();
      this.notModified();
    }
  },
  created() {
    this.timers.trySynchronize.time = this.time;
  },
};
</script>